import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/landing.css';

import { faGithub, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import Badge from 'react-bootstrap/Badge';
import { Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pdf from '../static/Calvin_Renaud_Resume_2023.pdf'
import React from 'react';
import styles from '../styles/styles'
import useScrollPosition from '../util/scrolleffect';

function LandingPageElement() {
  const scrollPosition = useScrollPosition();
  console.log(scrollPosition);
  return (
    <div className="App">
        <div className='fluid-container'>
        <div className='main-content'>
        <Container className='introduction'>
          <h1 className='name'>
            Calvin Renaud
          </h1>
          <br/>
          <h3 className='title'>
            Software Engineer at Bayer Crop Science
          </h3>
          <br/>
          <p className='statement'>
            I am a software engineer with expertise in full stack development and machine automation.
          </p>
          <div className='resume-link-div'>
          <a href={Pdf} className='resume-link'>Resume</a>
          </div>
          <Container className='icons'>
            <a href='https://www.linkedin.com/in/calvin-renaud-575ab2134' title='LinkedIn'>
            <FontAwesomeIcon icon={faLinkedin} size="xl" className='fa-icon'/>
            </a>
            <a href="https://github.com/cwrenaud">
            <FontAwesomeIcon icon={faGithub} size='xl' title='GitHub'/>
            </a>
            <a href='https://www.instagram.com/calvinrenaud/' title='Instagram'>
            <FontAwesomeIcon icon={faInstagram} size='xl'/>
            </a>
            </Container>
        </Container>
        <Container className='links'>
          <a className='hash-link' href='/#my-experience'>Experience</a>
          <br/>
          <a className='hash-link' href='/#about-me'>About Me</a>
        </Container>
          </div>
          <div className='about-content'>
            <Container className='experience' id="my-experience">
                <h2>Bayer Crop Science</h2>
                <h3>Software Engineer</h3>
                <h3 className='date'>Febuary 2022 - Present</h3>
                <p>
                    Deliver production, robust, and quality code in a fast paced and rapidly changing environment for
                    internal teams. Oversee development on large scale internal tools as well as 
                    with other teams to develop solutions for isolated cases. Maintain development deadlines
                    for multiple projects with hard seasonal deadlines.
                </p>
                <div className='badge-div'>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Python</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>React</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>PostgreSQL</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Git</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Typescript</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Javascript</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>PHP</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Docker</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Linux</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>HTML</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>CSS</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Svelte</Badge>
                </div>
                <br/>
                <h2>Renewable Energy Group</h2>
                <h3>Data Analyst</h3>
                <h3>October 2021 - January 2022</h3>
                <p>
                    Create and clean large scale dashboards and datasets for procurement of global resources. 
                    Develop automation tools for cleaning, transfering and gathering data from multiple sources. 
                    Review existing automation tools and redesign for increased performance.
                </p>
                <div className='badge-div'>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Python</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Tableau</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>SQL</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Excel</Badge>
                </div>
                <br/>
                <h2>Apple</h2>
                <h3>Software Engineering Intern</h3>
                <h3>June 2021 - October 2021</h3>
                <p>
                    Deliver an application that aimed at improving team efficiency using minimal cost resources.
                    Maintain a development timeline to ensure proper testing and production deployment. Quality assurance
                    testing to verify functionality and handle unexpected user inputs.
                </p>
                <div className='badge-div'>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Python</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>PostgreSQL</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Kubernetes</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Git</Badge>
                    <Badge className='test' style={styles.customBadge} pill bg='#393E46'>Docker</Badge>
                </div>
            </Container>
            <Container className='about' id="about-me">
                <h2>About Me</h2>
                <p>
                    I first started coding during my time at Iowa State University when getting my degree
                    in Physics. Several months later I began my internship at Apple and haven't looked back since.
                    At Apple I discovered I enjoyed the problem solving aspect of software engineering and from there
                    moved on to Renewable Energy Group then to my current position at Bayer. In my current position I
                    have the privilege of working on a variety of projects covering a wide range of skills and challenges.
                </p>
                <p>
                    Much of my time currently is taken up by my position at Bayer. I have an unfortunate tendency
                    to work on my projects there, and not my personal projects, since I find them interesting and challenging.
                    I cover a wide range of expertise covering the full stack development process to machine automation.
                </p>
                <p>
                    Outside of progamming and my work, I enjoy traveling when I can. My goal is to visit every continent and
                    at least 30 countries. Currently, I have been to three if I include the United States. When I am not working
                    or traveling, I enjoy watching movies, spending time with family and friends, and building Legos.
                </p>
            </Container>
          </div>
          </div>
        </div>
  );
}

export default LandingPageElement;
