import 'bootstrap/dist/css/bootstrap.min.css';

import LandingPageElement from "./pages/LandingPage"
import React from 'react';

function App() {
  return (
    <LandingPageElement/>
  );
}

export default App;
