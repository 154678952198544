const styles = {
    customBadge: {
        backgroundColor: '#393E46',
        color: '#EEEEEE',
    },
    customStack: {
        display: "flex"
    },
    customInPageLinkSelected: {
        color: "#00ADB5",
        
    }
};

export default styles;